import React from 'react';
import BlockContent from '@sanity/block-content-to-react';
import Link from './Link';
import { Text, Flex, Box } from 'rebass';
import { StandardText } from '../constants/typography';
import styled from '@emotion/styled';
import { path } from 'lodash/fp';
import Heading from '../PageBlocks/Heading';
import { getRawText } from '~lib/util';
import { ImageBase } from '../PageBlocks/Image';
import { css } from '@emotion/react';
import { space, layout, color, typography } from 'styled-system';
import { VideoBase } from '../PageBlocks/Video';
import PageBlockLink from '../PageBlocks/Link';
import KeyTakeaways from '~components/PageBlocks/KeyTakeaways';
import { PullQuoteBase } from '~components/PageBlocks/PullQuote';
import Tabs from '~components/PageBlocks/Tabs';
import AutismConnectBanner from '~components/PageBlocks/AutismConnectBanner';
import AAABanner from '~components/PageBlocks/AAABanner';
import Accordion from '~components/PageBlocks/Accordion';
import HighlightBox from '~components/PageBlocks/HighlightBox';
import ArticleCard from '~components/PageBlocks/ArticleCard';
import Columns from '~components/PageBlocks/Columns';
import MediaCard from '~components/PageBlocks/MediaCard';
import MediaResources from '~components/PageBlocks/MediaResources';

const BulletPoint = styled(Box)`
  height: 8px;
  width: 8px;
  min-height: 8px;
  min-width: 8px;

  border-radius: 50%;
  background: #0052cc;
  margin-right: 20px;
`;

const StyledUnorderedList = styled.ul`
  list-style: none;
  text-align: left;
  padding-left: 5px;
  margin-bottom: 0;
  counter-reset: list-counter;

  li {
    display: block;
    counter-increment: list-counter;
    margin-bottom: 12px;
    font-size: 16px;
  }
`;

const StyledOrderedList = styled.ol`
  list-style-type: 'decimal';
  counter-reset: list-counter;
  list-style: none;
  text-align: left;
  margin-bottom: 28px;
  margin-left: 38px;
  padding-top: 6px;

  li {
    display: block;
    margin-bottom: 12px;
    counter-increment: list-counter;
    position: relative;

    &::before {
      content: counter(list-counter);
      position: absolute;
      left: -38px;
      top: -2px;
      background: #0052cc;
      color: #fff;
      text-align: center;
      font-weight: bold;
      font-size: 16px;
      line-height: 28px;
      width: 28px;
      height: 28px;
      border-radius: 50%;
    }
  }
`;

const StyledListItem = styled.li`
  display: block;
  > ul {
    margin-top: 20px;
  }
  line-height: 1.5;
`;

const isEmptyBlock = props =>
  path('node._type')(props) === 'block' &&
  path('node.children.length')(props) === 1 &&
  path('node.children.0.text')(props) === '' &&
  path('node.children.0._type')(props) === 'span';

const blockMargins = props => css`
  margin-top: 28px !important;
  margin-bottom: 28px !important;
`;

const TextBlock = styled(Text)`
  ${blockMargins};
  line-height: 1.7;
  font-size: ${props => props.fontSize || '16px'};
  margin-top: 16px !important;
  margin-bottom: 16px !important;

  em {
    font-style: italic;
  }
`;

const Superscript = styled.sup`
  color: ${props => props.color};
  vertical-align: super;
`;

const textColor = path('mark.textColor');

const RichTextImage = styled(ImageBase)`
  margin-top: 10px !important;
  margin-bottom: 10px !important;
`;

const EmbedVideo = styled.div`
  ${blockMargins};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 100px;
  margin-bottom: 100px;

  iframe {
    width: 100%;
    min-height: 460px;

    @media screen and (max-width: 992px) {
      min-height: 200px;
    }
  }
`;

const serializers = {
  marks: {
    sup: props => {
      return (
        <Superscript
          className="superscript-block"
          inline={props._type === 'span'}
          color={textColor(props)}
        >
          {props.children}
        </Superscript>
      );
    },
    color: props => {
      return (
        <StandardText
          className="text-block"
          inline={props._type === 'span'}
          color={textColor(props)}
        >
          {props.children}
        </StandardText>
      );
    },
    link: props => {
      return (
        <Link
          className="link-block"
          style={{
            color: textColor(props),
          }}
          external
          to={props.mark.href}
        >
          {props.children}
        </Link>
      );
    },
    fileLink: props => {
      return (
        <Link
          className="link-block"
          style={{
            color: textColor(props),
          }}
          external
          to={props.mark.file.asset.url}
        >
          {props.children}
        </Link>
      );
    },
    internalLink: props => {
      return (
        <PageBlockLink
          className="link-block"
          style={{
            color: textColor(props),
          }}
          data={props.mark}
        >
          {props.children}
        </PageBlockLink>
      );
    },
  },
  list: ({ children, type }) => {
    return type === 'number' ? (
      <StyledOrderedList>{children}</StyledOrderedList>
    ) : (
      <StyledUnorderedList>{children}</StyledUnorderedList>
    );
  },
  listItem: ({ children, node }) => {
    return (
      <StyledListItem className="list-item-block">
        {node.listItem !== 'number' ? (
          <Flex flexDirection="row" alignItems="baseline">
            <BulletPoint className="list-item-bullet-point" mr="10px" />
            <Box>{children}</Box>
          </Flex>
        ) : (
          children
        )}
      </StyledListItem>
    );
  },
  types: {
    undefined: props => {
      // try extracting pure strings to text element
      if (!props.node) {
        return props.children;
      }

      const content = Object.values(props.node).join('');
      return (
        <TextBlock className="text-block" fontSize={props.node.style}>
          {content}
        </TextBlock>
      );
    },
    image: props => {
      return (
        <Flex justifyContent="flex-start" alignItems="flex-start">
          <RichTextImage
            className="image-block"
            inPage={true}
            transform={transformer =>
              transformer.auto('format').dpr(window?.devicePixelRatio || 1)
            }
            data={props.node}
          />
        </Flex>
      );
    },
    videoComponent: props => {
      return (
        <EmbedVideo>
          <VideoBase url={props.node.url} />
        </EmbedVideo>
      );
    },
    keyTakeawaysComponent: props => {
      return <KeyTakeaways data={props.node} />;
    },
    imageComponent: props => {
      return (
        <Flex justifyContent="flex-start" alignItems="flex-start">
          <RichTextImage
            className="image-block"
            inPage={true}
            transform={transformer =>
              transformer.auto('format').dpr(window?.devicePixelRatio || 1)
            }
            data={props.node}
          />
        </Flex>
      );
    },
    imageLinkComponent: props => {
      return (
        <Flex justifyContent="flex-start" alignItems="flex-start">
          <PageBlockLink data={props.node.link}>
            <RichTextImage
              className="image-block"
              inPage={true}
              transform={transformer =>
                transformer.auto('format').dpr(window?.devicePixelRatio || 1)
              }
              data={props.node}
            />
          </PageBlockLink>
        </Flex>
      );
    },
    tabsComponent: props => {
      return <Tabs data={props.node} />;
    },
    autismConnectBannerComponent: props => {
      return <AutismConnectBanner data={props.node} />;
    },
    aaaBannerComponent: props => {
      return <AAABanner data={props.node} />;
    },
    accordionComponent: props => {
      return <Accordion data={props.node} headingSize="h2" />;
    },
    highlightBoxComponent: props => {
      return <HighlightBox data={props.node} />;
    },
    articleCardComponent: props => {
      return <ArticleCard data={props.node} />;
    },
    columnsComponent: props => {
      return <Columns data={props.node} />;
    },
    mediaCardComponent: props => {
      return <MediaCard data={props.node} />;
    },
    mediaResourceComponent: props => {
      return <MediaResources data={props.node} />;
    },
    block: props => {
      const { style } = props.node;
      if (/^\d+px$/.test(style)) {
        return (
          <TextBlock
            className="text-block"
            inline={props._type === 'span'}
            fontSize={style}
          >
            {props.children}
          </TextBlock>
        );
      }

      if (/^h\d+$/.test(style)) {
        return (
          <Heading
            mt={0}
            mb={0}
            textAlign="left"
            data={{
              level: style,
              text: props.children,
            }}
            className="heading-block"
          />
        );
      }

      if (style === 'blockquote') {
        return (
          <PullQuoteBase
            my={{
              xs: 50,
              tabletP: 60,
              lg: 60,
            }}
            className="blockquote-block"
          >
            {props.children}
          </PullQuoteBase>
        );
      }

      if (isEmptyBlock(props)) {
        return <br />;
      }

      return (
        <TextBlock className="text-block">
          {BlockContent.defaultSerializers.types.block(props)}
        </TextBlock>
      );
    },
  },
};

const richTextContentCss = props => css`
  > .blockquote-block:first-child {
    margin-top: 0;
  }
  > .blockquote-block:last-child {
    margin-bottom: 0;
  }

  > .image-block:first-child {
    margin-top: 0;
  }
  > .image-block:last-child {
    margin-bottom: 0;
  }

  > .divider-block:first-child {
    margin-top: 0;
  }
  > .divider-block:last-child {
    margin-bottom: 0;
  }

  > .text:first-child {
    margin-top: 0;
  }
  > .text-block:last-child {
    margin-bottom: 0;
  }
  ${props.forceColor
    ? `
        &&& {
            .text-block,
            .heading-block,
            .blockquote-block,
            .superscript-block,
            .list-item-block,
            .link-block {
              color: ${props.forceColor} !important;
            }
          }
  `
    : ''}
`;

//TODO heading-block should be blue 160

const StyledMultiBlockContent = styled(BlockContent)`
  width: 100%;
  max-width: 824px;
  margin: 0 auto;
  ${space};
  ${layout};
  ${color};
  ${typography};
  ${richTextContentCss}
`;

const StyledSingleBlockContent = styled(Box)`
  ${richTextContentCss}
`;

const SanityRichTextContent = ({
  content,
  forceColor,
  renderRawText,
  ...props
}) => {
  if (renderRawText) {
    return renderRawText(getRawText(content));
  }
  const blockContent = (
    <StyledMultiBlockContent
      {...props}
      forceColor={forceColor}
      blocks={content}
      serializers={serializers}
    />
  );

  return content ? (
    content.length > 1 ? (
      blockContent
    ) : (
      <StyledSingleBlockContent forceColor={forceColor} {...props}>
        {blockContent}
      </StyledSingleBlockContent>
    )
  ) : null;
};

export default SanityRichTextContent;
